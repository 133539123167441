require('./bootstrap');
import Vue from 'vue';
window.Vue = Vue;

import 'es6-promise/auto';

import Vuex from 'vuex';
Vue.use(Vuex);

import VueRouter from 'vue-router';
Vue.use(VueRouter);

import axios from 'axios';
window.axios = axios;

import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);


window.axios.defaults.headers.common = {
  'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
}

import router from './routes';

import * as VueGoogleMaps from 'vue2-google-maps';
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyA-F-ZPdYkGBu0UmxJMgfDehn6iYSCOi2g",
    libraries: "places"
  }
});


Vue.component('perfilhome', require('./components/pefilhome.vue').default);

const store = new Vuex.Store({
  state:{
    avatar: '/img/profile/avatar.png',
    avatarIsChange: 0,
    usrLastLogin:'',
    usrOlderLogin:'',
    slides:[],
    sucursales:[],
  },
  getters:{
    getUptdatedAvatar: state => {
      return state.avatar;
    },
    getAvatarState: state => {
        return state.avatarIsChange;
    },
    getusrLastLogin: state =>{
        return state.usrLastLogin;
    },
    getusrOlderLogin: state =>{
        return state.usrOlderLogin;
    },
    getSlides: state =>{
        return state.slides;
    },
    getSucursales: state =>{
        return state.sucursales;
    },

  },
  mutations:{
    updateAvatar(state, url){
      state.avatar = url;
    },
    avatarChange(state){
      state.avatarIsChange = _.toString(_.now()).substring( _.toString(_.now()).length-4, _.toString(_.now()).length);
    },
    setUsrlastLogin(state,data){
      state.usrLastLogin = data.last_login_at;
      state.usrOlderLogin = data.older_login_at;
    },
    updateSlides(state, data){
      state.slides = data;
    },
    updateSucursales(state, data){
      state.sucursales = data;
    },
  },
  actions:{
    UpdateProfile(){
      return state.avatar;
    }
  }
});


 new Vue({
    el: '#app',
    store,
    router,
    data:{

    }
});
