<template>
  <div>
    <!-- template -->
    <router-link to="/perfil">
    <div class="avatar-box">
      <figure v-show="this.avatarUrl">
        <img :src="avatar" refs="perfilimage" :alt="this.perfil.data.name">
      </figure>

      <div class="avatar-data">
        <p>{{this.getName(this.perfil.data.name)}}</p>
      </div>
    </div>
  </router-link>
    <!-- template -->
  </div>
</template>

<script>

import Perfil from '../Classes/Perfil.js';

export default {
  data(){
    return{
      perfil: new Perfil(),
      avatarUrl: null,
      lastlogin:0
    }
  },
  async mounted(){
    await this.perfil.show();
  },
  computed:{
    avatar(){
      return this.$store.getters.getUptdatedAvatar;

    }


  },
  watch:{
      'perfil.data.avatar': function(val){
        if (val) {
          this.$store.commit('updateAvatar', val);
          this.avatarUrl = this.$store.state.avatar;
        }
      },
      'perfil.data': function(val){
        if (val) {
          this.$store.commit('setUsrlastLogin', val);
        }
      }

  },
  methods:{
    getName(pname){
      let texto = new String(pname);
      texto = texto.split(' ');
      return texto[0];
    }
  }

}
</script>

<style lang="scss" scoped>

  @import "../../sass/admin/variables";

  .avatar-box{
    text-align: center;
    padding-top: 24px;
    border-bottom: 1px $grey solid;
    margin-bottom: 24px;
  }
  .avatar-data{
    padding-bottom: 24px;
    margin-top: 8px;
    text-align: center;
  }
  figure{
    display: inline-block;
    width: 64px;
    img{
      display: block;
      width: 100%;
    }
  }

</style>
